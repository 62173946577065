export default class OP3Pixels {
    static utmTerms = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content' ,'utm_term', 'fbclid'];
    static utmIds = ['fbadid', 'gadid', 'padid', 'bingid'];

    static fireFacebookEvent(eventId, eventName, eventData) {
        let defaultEventTypes = [
                'PageView',
                'ViewContent',
                'Search',
                'AddToCart',
                'AddToWishlist',
                'InitiateCheckout',
                'AddPaymentInfo',
                'Purchase',
                'Lead',
                'Subscribe',
            ],
            trackType = 'track';

        if (! defaultEventTypes.includes(eventName)) {
            if (eventName === 'Scroll') {
                trackType = 'trackCustom';
            }
        }

        // checking if our events are present, otherwise
        // do not fire events
        if (! defaultEventTypes.includes(eventName) && eventName !== "Scroll") {
            return;
        }

        // setup everything
        this.setAllQueryVars();

        // collect the data and merge with event data
        let standardData = this.getInitialEventData();
        eventData = {...standardData, ...eventData };

        // for Lead event we are saving data in cookie for later use (matching)
        if (eventName === 'Lead') {
            var expires = parseInt(window.OPDashboardPixels.facebookFirstVisitCookieDuration);
            this.setCookie('op3_matching', JSON.stringify(eventData), expires);
        }

        // track through pixel
        fbq(trackType, eventName, eventData, {"eventID": eventId});

        // track through CAPI (using AJAX) if enabled
        if (window.OPDashboardPixels.enableCapi === "1" && window.OPDashboardPixels.capiAccessToken) {
            var json = {
                pixel: 'facebook',
                pixelID: window.OPDashboardPixels.fbPixelId,
                eventName: eventName,
                eventData: eventData,
                url: window.location.href,
                eventID: eventId,
            };

            setTimeout(function(){
                jQuery.post( {
                    url: window.OPDashboardPixels.capiRestUrl,
                    data: json,
                    headers: {
                        'Cache-Control': 'no-cache'
                    },
                    success: function(){},
                });
            },500)
        }
    }

    static getInitialEventData() {
        let initialData =  window.OPDashboardPixels.standardParams;
        let dateTime = this.getDateTime();

        initialData.landing_page = window.location.href.split('?')[0];
        initialData.traffic_source = this.getTrafficSource();
        initialData.event_time = dateTime[0];
        initialData.event_day = dateTime[1];
        initialData.event_month = dateTime[2];
        initialData.event_day_in_month = dateTime[3];

        let utmTerms = this.getUTMs(window.OPDashboardPixels.facebookAttribution === "last_visit");

        this.utmTerms.forEach(function (term, index) {
            if (term in utmTerms) {
                initialData[term] = utmTerms[term];
            }
        });

        return initialData;
    }

    static getMatchingDataFromCookie() {
        let cookieValue = this.getCookie('op3_matching');
        let result = {};

        if (cookieValue) {
            let temp = JSON.parse(cookieValue);
            if (temp.email)
                result.email = temp.email;
            if (temp.first_name)
                result.first_name = temp.first_name;
            if (temp.last_name)
                result.last_name = temp.last_name;
        }

        return result;
    }

    static getDateTime() {
        let dateTime = [];
        let date = new Date(),
            days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            months = ['January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ],
            hours = ['00-01', '01-02', '02-03', '03-04', '04-05', '05-06', '06-07', '07-08',
                '08-09', '09-10', '10-11', '11-12', '12-13', '13-14', '14-15', '15-16', '16-17',
                '17-18', '18-19', '19-20', '20-21', '21-22', '22-23', '23-24'
            ];

        dateTime.push(hours[date.getHours()]);
        dateTime.push(days[date.getDay()]);
        dateTime.push(months[date.getMonth()]);
        dateTime.push(date.getDate());

        return dateTime;
    }

    /**
     * Return UTM terms from cookies.
     */
    static getUTMs(useLast = false) {
        try {
            let cookiePrefix = 'op_'
            let _this = this;
            if (useLast) {
                cookiePrefix = 'last_op_'
            }
            let terms = [];
            this.utmTerms.forEach(function (name, index) {
                // check in Cookie
                if (_this.getCookie(cookiePrefix + name)) {
                    let value = _this.getCookie(cookiePrefix + name);
                    terms[name] = _this.filterEmails(value);
                }
            });

            return terms;

        } catch (e) {
            console.error(e);
            return [];
        }
    }

    static setAllQueryVars() {
        let expires = parseInt(window.OPDashboardPixels.facebookFirstVisitCookieDuration);
        let queryVars = this.getQueryVars();
        let landing = window.location.href.split('?')[0];
        let _this = this;

        try {
            // save data for first visit
            if (this.getCookie('op_first_visit') === null) {
                this.setCookie('op_first_visit', true, expires);
                this.setCookie('op_traffic_source', this.getTrafficSource(), expires);
                this.setCookie('op_landing_page', landing, expires);
                _this.utmTerms.forEach(function (name, index) {
                    if (queryVars.hasOwnProperty(name)) {
                        _this.setCookie('op_' + name, queryVars[name], expires);
                    } else {
                        _this.deleteCookie('op_' + name)
                    }
                });
                _this.utmIds.forEach(function(name, index) {
                    if (queryVars.hasOwnProperty(name)) {
                        _this.setCookie('op_' + name, queryVars[name], expires);
                    } else {
                        _this.deleteCookie('op_' + name)
                    }
                })
            }

            // save data for last visit if it is a new session
            if (this.checkSession()) {
                this.setCookie('last_op_traffic_source', this.getTrafficSource(), expires);
                this.utmTerms.forEach(function (name, index) {
                    if (queryVars.hasOwnProperty(name)) {
                        _this.setCookie('last_op_' + name, queryVars[name], expires);
                    } else {
                        _this.deleteCookie('last_op_' + name);
                    }
                });
                this.utmIds.forEach(function(name, index) {
                    if (queryVars.hasOwnProperty(name)) {
                        _this.setCookie('last_op_' + name, queryVars[name], expires);
                    } else {
                        _this.deleteCookie('last_op_' + name);
                    }
                })

                this.setCookie('last_op_landing_page', landing, expires);
            }
        } catch (e) {
            console.error(e);
        }
    }

    static getQueryVars() {
        try {
            var result = {},
                tmp = [];

            window.location.search
                .substring(1)
                .split("&")
                .forEach(function (item) {
                    tmp = item.split('=');

                    if (tmp.length > 1) {
                        result[tmp[0]] = tmp[1];
                    }

                });

            return result;

        } catch (e) {
            console.error(e);
            return {};
        }
    }

    static getTrafficSource() {
        try {

            let referrer = document.referrer.toString(),
                source;

            let direct = referrer.length === 0;
            let internal = direct ? false : referrer.indexOf(OP3.Meta.siteUrl) === 0;
            let external = ! direct && ! internal;

            if (external === false) {
                source = 'direct';
            } else {
                source = referrer;
            }

            if (source !== 'direct') {
                return this.getDomain(source);
            } else {
                return source;
            }

        } catch (e) {
            console.error(e);
            return 'direct';
        }
    }

    static getDomain(url) {
        url = url.replace(/(https?:\/\/)?(www.)?/i, '');

        if (url.indexOf('/') !== -1) {
            return url.split('/')[0];
        }

        return url;
    }

    static checkSession() {
        let duration = window.OPDashboardPixels.facebookLastVisitCookieDuration / 1440;

        if (this.getCookie('op_start_session') === null ||
            this.getCookie('op_session_limit') === null) {
            this.setCookie('op_session_limit', true, duration);
            this.setCookie('op_start_session', true);

            return true;
        }

        return false;

    }

    static setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }

        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }

    static getCookie(name) {
        var nameEQ = name + "=";
        var ca = decodeURIComponent(document.cookie).split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }

        return null;
    }

    static deleteCookie(name) {
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    static isEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    static filterEmails(value) {
        return this.isEmail(value) ? undefined : value;
    }
}

window.OP3Pixels = OP3Pixels;